<template>
  <div>
    <Echart
        :options="options"
        id="RightTopRightChart"
        height="320px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '12%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: newData.category,
              axisLabel: {
                textStyle: {// 设置字体颜色
                  fontSize: 16, // 设置字体大小
                  fontWeight: 'bold'
                },
                interval: 0,
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                textStyle: {// 设置字体颜色
                  fontSize: 16 // 设置字体大小
                },
                formatter: function (value) {
                  return value + '%';
                }
              },
              splitLine: {show: true},
            },
          ],
          series: [
            {
              name: '优品率',
              type: 'bar',
              barWidth: '60%',
              data: newData.data,
              label: {
                show: true, // 显示标签
                position: 'top', // 标签位置，可以是 'top', 'insideTop', 'inside', 'insideBottom' 等
                color: 'white', // 标签颜色
                fontSize: 16, // 字体大小
                formatter: function (params) {
                  // 判断是否为浮点数
                  if (Number.isInteger(params.value)) {
                    return params.value + '%';
                  } else {
                    // 保留一位小数
                    return params.value.toFixed(1) + '%';
                  }
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  if (Number.isInteger(value)) {
                    return value + '%';
                  } else {
                    // 保留一位小数
                    return value.toFixed(1) + '%';
                  }
                }
              },
            }
          ],
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
