<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        category: [],
        data: [],
      }
    };
  },
  components: {
    Chart,
  },
  props: {
    quality_rate: {
      type: Array,
      required: true,
    },
  },
  watch: {
    quality_rate: {
      handler: function (newVal) {
        console.log(newVal, 'newVal')
        this.cdata.category = []
        this.cdata.data = []
        newVal.forEach((item, index) => {
          this.cdata.category[index] = item.item_type
          this.cdata.data[index] = item.percent
        })
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
