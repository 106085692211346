<template>
  <div id="live" ref="appRef">
    <div class="bg">
      <div class="live-body">

        <MyHeader :title=title></MyHeader>

        <div class="body-box">
          <!-- 顶部数据 -->
          <div class="top-box">
            <dv-border-box-12>
              <top :info="info"/>
            </dv-border-box-12>
          </div>
          <!--主要数据-->
          <div class="content-box">
            <div class="left-box">
              <div class="left-top-box">
                <dv-border-box-12>
                  <leftTop :quality_rate="quality_rate"/>
                </dv-border-box-12>
              </div>
              <div class="left-bottom-box">
                <dv-border-box-12>
                  <leftBottom :status_num="status_num"/>
                </dv-border-box-12>
              </div>
            </div>
            <div class="center-box">
              <dv-border-box12>
                <mid :machines="machines"/>
              </dv-border-box12>
            </div>
            <div class="right-box">
              <div class="right-top-box">
                <dv-border-box-12>
                  <rightTop :anomalys="anomalys"/>
                </dv-border-box-12>
              </div>
              <div class="right-bottom-box">
                <dv-border-box-12>
                  <rightBottom :productivity="productivity"/>
                </dv-border-box-12>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import drawMixin from "../../utils/drawMixin";
import MyHeader from "../../components/header/myHeader.vue";
import top from './top.vue'
import leftTop from './leftTop.vue'
import leftBottom from './leftBottom.vue'
import mid from './mid.vue'
import live from "@/api/live";
import rightTop from "@/views/live/rightTop.vue";
import rightBottom from "@/views/live/rightBottom.vue";
import rank from "@/api/rank";
import {getNowFormatDate} from "@/utils/date";
import stock from "@/api/stock";
import order from "@/api/order";

export default defineComponent({
  name: "live",
  data() {
    return {
      title: "生产实况",
      status_num: {},
      oee: {},
      machines: [],
      anomalys: [],
      productivity: [],
      quality_rate: [],
      info: {},
    }
  },
  mixins: [drawMixin],
  components: {
    MyHeader, top, leftTop, leftBottom, mid, rightTop, rightBottom
  },
  mounted() {
    // setInterval(() => {
    //   this.fetchData();
    // }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let res = {};
      res.machine_cnt = 0;
      live.getLiveInformation(1).then(
          (response) => {
            // console.log(response.data.data)
            this.status_num = response.data.data.status_num;
            this.oee = response.data.data.oee;
            this.machines = response.data.data.machines;
            this.anomalys = response.data.data.anomalys;
            res.machine_cnt += response.data.data.status_num.num_of_run+response.data.data.status_num.num_of_waiting+response.data.data.status_num.num_of_error+response.data.data.status_num.num_of_close;
          }
      ).catch(
          (error) => {
            console.log(error)
          }
      )
      live.getLiveProductivity(1).then(
          (response) => {
            this.productivity = response.data.data.ItemCount2.filter(item => item.item_type === '滚针轴承零件');
            res.workpiece_cnt = response.data.data.ItemCount2.length;
            this.quality_rate = response.data.data.ItemCount1;
            // console.log(this.quality_rate)
          }
      ).catch(
          (error) => {
            console.log(error)
          }
      )

      //top info

      rank.getRankInfoAnalysis(getNowFormatDate(), getNowFormatDate()).then(
          (response) => {
            // console.log(response.data.data)
            res.worker_cnt = response.data.data.length;
          }).catch(
          (error) => {
            console.log(error)
          })
      res.stock_cnt = 0;
      stock.getStockInfoAnalysis(10, 1, 1).then(
          (response) => {
            console.log(response.data.data.stativentorys)
            response.data.data.stativentorys.forEach(item => {
              res.stock_cnt += item.stat_num;
              console.log(res.stock_cnt)
            })
          }).catch(
          (error) => {
            console.log(error)
          })

      order.getOrderInfoAnalysis(1, 10, 1).then(
          (response) => {
            // console.log(response.data.data)
            res.order_cnt = response.data.data.total;
          }).catch(
          (error) => {
            console.log(error)
          })

      this.info = res;
    },


  },

})
</script>

<style scoped lang="scss">
@import '../../assets/scss/live.scss';
</style>
