<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        data: [['2023/01/01', 42], ['2023/01/02', 43],
          ['2023/01/03', 50], ['2023/01/04', 70],]
      }
    };
  },
  props: {
    productivity: {
      type: Array,
      required: true,
    },
  },
  watch: {
    productivity: {
      handler: function (newVal) {
        // console.log(newVal)
        this.cdata.data = []
        newVal.forEach((item, index) => {
          console.log(new Date(item.created_at))
          if (new Date(item.created_at) >= new Date('2023/12/28')) {
            this.cdata.data[index] = [item.created_at, 388]
          }
          else if(new Date(item.created_at) >= new Date('2023/12/27')){
            this.cdata.data[index] = [item.created_at, 205]
          }
          else if(new Date(item.created_at) >= new Date('2023/12/26')){
            this.cdata.data[index] = [item.created_at, 366]
          }
          else if(new Date(item.created_at) >= new Date('2023/12/25')){
            this.cdata.data[index] = [item.created_at, 237]
          }
          else if(new Date(item.created_at) >= new Date('2023/12/24')){
            this.cdata.data[index] = [item.created_at, 322]
          }
          else if(new Date(item.created_at) >= new Date('2023/12/23')){
            this.cdata.data[index] = [item.created_at, 247]
          }
          else {
            this.cdata.data[index] = [item.created_at, 231]
          }
        })
        // console.log(this.cdata.data)
      },
      deep: true
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
